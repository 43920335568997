@import-normalize;
@import-sanitize;
@import 'vars';
@import 'fonts';
@import 'mixins';

:global {
  * {
    box-sizing: border-box;
  }

  button {
    border: none;
    background-color: $transparent;
    outline: none;
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-size: $font-size-root;
    @include till-desktop-s {
      font-size: $fontSize-14;
    }
  }

  body {
    color: $blue-dark;
    font-family: $font-family;
    font-size: $fontSize-14;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: $font-family-title;
    margin: 1rem 0;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: $purple;
    }
  }

  img {
    width: 100%;
  }

  .swiper-button-prev {
    background-image: url("../images/icons/chevronArrowLeft.svg") !important;
    background-repeat: no-repeat;
    background-position: left;

    &::after {
      display: none;
    }
  }

  @include rtl {
    .swiper-button-prev {
      background-image: url("../images/icons/chevronArrowRight.svg") !important;
      background-position: right;
    }
  }

  .swiper-button-next {
    background-image: url("../images/icons/chevronArrowRight.svg") !important;
    background-repeat: no-repeat;
    background-position: right;

    &::after {
      display: none;
    }
  }

  @include rtl {
    .swiper-button-next {
      background-image: url("../images/icons/chevronArrowLeft.svg") !important;
      background-position: left;
    }
  }
}
