@import 'mixins';

// Font family
$font-family: 'Poppins', sans-serif;
$font-family-title: 'Abril Fatface', sans-serif;

// Root font size
$font-size-root: 16px;

$header-height: 80px;

// Font sizes
$fontSize-12: size(12px);
$fontSize-14: size(14px);
$fontSize-16: size(16px);
$fontSize-20: size(20px);
$fontSize-26: size(26px);
$fontSize-32: size(32px);
$fontSize-38: size(38px);
$fontSize-42: size(42px);
$fontSize-50: size(50px);
$fontSize-70: size(70px);

// Font weights
$fontWeight-300: 300;
$fontWeight-400: 400;
$fontWeight-500: 500;
$fontWeight-600: 600;
$fontWeight-700: 700;

// Colors
$white: #fff;
$grey-light: #f5f7f9;
$grey: #dce1e6;
$grey-dark: #6d768e;
$black: #000;
$green-light: #c2efdf;
$green-primary: #67d8af;
$green-dark: #317033;
$green-darkest: #153016;
$purple-light: #f0e2ff;
$purple: #b283e4;
$light-cyan: #d7fafa;
$cyan: #61edea;
$blue-dark: #081e3f;
$orange: #efd2c2;
$red: #f26d65;
$transparent: transparent;

// Gradients
$gradient-light-purple: linear-gradient(160deg, $purple-light 0%, $white 50%);
$gradient-light-purple-cyan: linear-gradient(0deg, rgba(240, 226, 255, 0) 0%, #f5f7f9 100%),
  linear-gradient(337deg, #d7fafa 45.35%, #f0e2ff 61.03%, #fffce2 77.37%);

// Z-indexes
$z-index-nav: 99;
$z-index-modal: 100;
$z-index-negative: -1;
$z-index-reset: 0;
$z-index-positive: 1;

// Screen sizes
$desktop-xl: 1920px;
$desktop-l: 1440px;
$desktop-m: 1366px;
$desktop: 1200px;
$desktop-s: 1024px;
$tablet: 768px;
$mobile: 576px;
$min-width: 320px;

// borders
$border-thin-grey: 1px solid $grey;
$border-thin-green: 1px solid $green-primary;

// Border radiuses
$checkbox-radius: size(4px);
$border-radius: size(20px);
$border-radius-l: size(40px);
$border-radius-xl: size(100px);
$select-radius: size(8px);
$progress-bar-radius: size(8px);
$input-border-radius: size(8px);
$select-menu-radius: 0 0 $select-radius $select-radius;
$no-border-radius: 0;
$rounded-border: 50%;

// Shadows
$shadow-modal: 0 4px 50px $purple;
$shadow-purple: 0 -20px 110px -20px $purple-light;
$shadow-grey: 0 10px 32px #49474914;
