@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-weight: $fontWeight-500;
  font-style: normal;
  src: url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-weight: $fontWeight-600;
  font-style: normal;
  src: url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-weight: $fontWeight-700;
  font-style: normal;
  src: url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Abril Fatface';
  font-weight: $fontWeight-400;
  font-style: normal;
  src: url('../fonts/AbrilFatface/AbrilFatface-Regular.ttf') format('truetype');
}
