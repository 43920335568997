.globalLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fullScreen {
  height: 100vh;
  width: 100vw;
}

.fillContainer {
  height: 100%;
  width: 100%;
}

.size100 {
  width: 100%;
  height: 100px;
}

.size40 {
  width: 100%;
  height: 40px;
}
