@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@function size($size) {
  $remSize: $size / $font-size-root;
  @return #{$remSize}rem;
}

// trim with ... on one or multiple lines
@mixin trim-text($numLines: null, $line-height: 24px) {
  @if $numLines != null {
    line-height: $line-height;
    height: $line-height * $numLines;
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    -moz-orient: vertical;
    overflow: hidden;
  } @else {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}

@mixin rtl {
  html[dir='rtl'] {
    @content;
  }
}

@mixin till-desktop-s {
  @media (max-width: $desktop-s - 1) {
    @content;
  }
}

@mixin till-desktop {
  @media (max-width: $desktop - 1) {
    @content;
  }
}

@mixin till-desktop-l {
  @media (max-width: $desktop-l - 1) {
    @content;
  }
}

@mixin till-tablet {
  @media (max-width: $tablet - 1) {
    @content;
  }
}

@mixin till-mobile {
  @media (max-width: $mobile - 1) {
    @content;
  }
}

@mixin at-tablet {
  @media (min-width: $tablet) and (max-width: $desktop-s - 1) {
    @content;
  }
}

@mixin at-desktop-s {
  @media (min-width: $desktop-s) and (max-width: $desktop-l - 1) {
    @content;
  }
}
